import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import s from './Blog.module.css';
export function FetchBlogsData(data) {
    const container = useSelector((state) => state);
    const lang = window.localStorage.getItem('lang');
   return  data.map((element) => {
    return (
        <div id={s.Blog} key={element.id} className='post-cont col-lg-3 equal-height-col col-md-5 col-auto mt-2'>
            <Link to={"/post/" + element.id}>
                <img id={s.BlogImg} src={ element.img } width="100%" height="50%" alt=''/>
                <h4 className='mt-3'>{ element.title }</h4>
            </Link>
            <div>
                <h5 className='mb-5'>{ element.subtitle }</h5>
                <div className={s.BlogInfo + ' row post-info'} dir={container.language.Lang === 'eng' && lang === 'eng' ? "ltr": "rtl"}>
                    <h6 className='col-6'>{container.language.Lang === 'eng' && lang === 'eng' ? "time": "الساعه"}: { element.time } </h6>
                    <h6 className='col-6'>{container.language.Lang === 'eng' && lang === 'eng' ? "day": "اليوم"}:  ({ element.day }) { element.date } </h6>
                </div>
            </div>
        </div>
        );
    })
}

export function FetchBlogDetailData(props) {
    const container = useSelector((state) => state);
    const lang = window.localStorage.getItem('lang');
    return (
        props.data ? 
        <>
            <div className='container' dir={container.language.Lang === 'eng' && lang === 'eng' ? "ltr" : "rtl" }>
                <center><img id={s.BlogDetailImg} style={{borderRadius:"20px"}} src={props.data.img} alt="" width={"80%"}/></center>
                <h1 className='mt-4 mb-3'><u>{props.data.title}</u></h1>
                <div className={ container.language.Lang === 'eng' && lang === 'eng' ? 'mt-2 mb-5 ' + s.Blogdes + " " + s.Blogdesl  : 'mt-2 mb-5 ' + s.Blogdes + " " } >
                    { props.data.des }
                </div>
                <hr />
                <h6>{container.language.Lang === 'eng' && lang === 'eng' ? "day": "اليوم"}: ({props.data.day})  { props.data.date }</h6>
                <h6>{container.language.Lang === 'eng' && lang === 'eng' ? "time": "الساعه"}: { props.data.time }</h6>
            
            </div>
        </>
        :
        <h1 className='alert alert-info text-center'> {container.language.Lang === 'eng' && lang === 'eng' ? "this Post isn't existing" : "هذه التدوينه غير موجوده"} </h1>
    )
}