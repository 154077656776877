import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Title from '../../common/Title';
import { lang } from '../../common/Dispatching';
import Nav from '../../common/Nav';
import Cont from '../../common/Cont';
import Blogs from '../dataAr/BlogDataAr';
import { FetchBlogsData } from '../../../fetching/FetchBlogsData';

export default function BlogAr(){
    useEffect(() => {
        const board = document.querySelector('.bi-globe2');
        board.style.color = 'mediumvioletred';
    });
    const dispatch = useDispatch();
  return (
    <div className='m-0' dir="rtl">
        <Title
                title={
                    <>
                        <h1 className="title-txt">مدونة</h1>
                        <h1 onClick={()=>{lang(dispatch);}} className="title-txt" title="click for english language">
                            <i className="bi bi-arrow-left-circle-fill"></i>
                        </h1>
                        <h1 className="title-txt">ساسك</h1>
                    </>
                }
            />
        <Cont 
        content={
            <div className='row justify-content-center align-items-center me-auto'>
                {
                    Blogs.length > 0 ? FetchBlogsData(Blogs) : <h1 className='alert alert-info text-center'> لا يوجد أي تدوينات حتي الأن </h1>
                }
            </div>
        }
        />
        <Nav />
    </div>
);};
