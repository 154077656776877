import React, { useEffect } from 'react'
import Cont from '../../common/Cont';
import Nav from '../../common/Nav';
import Title from '../../common/Title';
import { lang } from '../../common/Dispatching';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBlogDetailData } from '../../../fetching/FetchBlogsData';
import { Blogs } from '../../EN/data/BlogsDataEn';
import BlogsAr  from '../dataAr/BlogDataAr';
import { useParams } from 'react-router-dom';
export default function BlogDetailAr(props) {
    useEffect(() => {
        const board = document.querySelector('.bi-globe2');
        board.style.color = 'mediumvioletred';
    }, []);
    const dispatch = useDispatch();
    const id = useParams();
    const container = useSelector((state) => state);
    const langs = window.localStorage.getItem('lang');
  return (
    <div className='m-0' dir={container.language.Lang === 'eng' && lang === 'eng' ? "ltr" : "rtl" }>
        <Title
                title={
                    <>
                        <h1 className="title-txt">{container.language.Lang === 'eng' && langs === 'eng' ? "Blog": "مدونة"}</h1>
                        <h1 onClick={()=>{lang(dispatch);}} className="title-txt" title="click for english language">
                            <i className="bi bi-arrow-left-circle-fill"></i>
                        </h1>
                        <h1 className="title-txt">{container.language.Lang === 'eng' && langs === 'eng' ? "SASKE" : "ساسك"}</h1>
                    </>
                }
            />
        <Cont content={
            < FetchBlogDetailData data={container.language.Lang === 'eng' && langs === 'eng' ? Blogs.find((blog) =>  blog.id === id.id ) : BlogsAr.find((blog) =>  blog.id === id.id )} />
        }
        />
        <Nav />
    </div>
);};