import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Title from '../../common/Title';
import { lang } from '../../common/Dispatching';
import Nav from '../../common/Nav';
import Cont from '../../common/Cont';
import { FetchBlogsData } from '../../../fetching/FetchBlogsData';
import { Blogs } from '../data/BlogsDataEn';

export default function Blog() {
    const dispatch = useDispatch();
    useEffect(() => {
        const board = document.querySelector('.bi-globe2');
        board.style.color = 'mediumvioletred';
    });

  return (
    <div className='m-0'>
        <Title
				title={
					<>
						<h1 className="title-txt">SASKE</h1>
						<h1 onClick={()=>{lang(dispatch);}} className="title-txt" title="click for english language">
							<i className="bi bi-arrow-right-circle-fill"></i>
						</h1>
						<h1 className="title-txt">Blog</h1>
					</>
				}
			/>
      <Cont content={
          <div className='row justify-content-center align-items-center me-auto'>
                {Blogs.length > 0 ? FetchBlogsData(Blogs) : <h1 className='alert alert-info text-center'> Don't exists any published Blogs </h1>}
            </div>
        }
        />
        <Nav />
    </div>
  )
}
