import React, { useEffect } from 'react';
import '../../EN/screens/style/mainscreen.css';
import Title from '../../common/Title';
import Cont from '../../common/Cont';
import Nav from '../../common/Nav';
import { lang } from '../../common/Dispatching';
import { useDispatch } from 'react-redux';

function NotFoundEn() {
	const dispatch = useDispatch();
	useEffect(() => {
		const board = document.querySelector('.bi-easel3-fill');
		const img = document.querySelectorAll('#cont-img');
		board.style.color = 'green';
		for (let i = 0; i < img.length; i++){
			img[i].style.float = 'left';
		}
	});
	return (
		<div dir="rtl">
			<Title
				title={
					<>
						<h1 className="title-txt">SASKE </h1>
						<h1
							onClick={() => {
								lang(dispatch);
							}}
							className="title-txt"
							title="click for english language"
						>
							<i className="bi bi-arrow-right-circle-fill"></i>
						</h1>
						<h1 className="title-txt">Company</h1>
					</>
				}
			/>
			<Cont
				content={
					<div>
						<h1 className='alert alert-info text-center'>this page isn't found</h1>
					</div>
				}
			/>
			<Nav />
		</div>
	);
}

export default NotFoundEn;