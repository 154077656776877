import jotvsjit from '../../../../media/blogimgs/jotvsait.jpg';
import Dos from '../../../../media/blogimgs/Dos.jpg';
import maincon from '../../../../media/blogimgs/icmaincontrol.jpg';
export const Blogs = [
    {
        id:"4",
        title: "Main Controller IC",
        subtitle: "Have You heared about a Component which Control on every thing on the PC ... it isn't the processor in this article i will tell You about the main controller IC......",
        day:"Sunday",
        date: "23-2-2025",
        time:"11:59 am",
        img: maincon,
        des: 
        <p>
            Main controller IC
            It is the controller for setting the current-voltage necessary to run the processor<br/>
            The processor circuit contains a socket atx 12 and some channels to generate fit voltage to run the processor<br/>
            Every channel contains an 
            <ul>
                <li>
                    upper mosfet
                </li>
                <li>
                    several lower mosfets
                </li>
                <li>
                    some capacitors for Softening the current
                </li>
                <li>
                    and one coil
                </li>
            </ul>
            so we can count the number of channels in the processor circuit by counting the number of coils near the circuit<br/> and on those coils, we can track the fit voltage to run the processor which is called Vcore.<br/>
            Starting run the processor by feeding the atx socket 12 volt from the yellow wire from the power supply which is connected to the motherboard power supply is pass 12 volts to the drain of the upper mosfet which such as the switch <br/> and the controller of it is the main controller IC our topic today is it.<br/>
            This IC for running needs to 5 volts almost it is on pin (10)<br/> the pic below explains that it needs another necessary volt Vtt is the generating volt from the CPU it is telling the IC what the kind of CPU is and what is the fit voltage to run it<br/> IC generates a beat on the gate of upper mosfet by pins which it is explaining on the pic below it is Lgate, Ugate, Boot which U stands for upper and L for lower and Boot it is working on both of them and the number is forward of it is the number of channels the beat toward it to generate the fit voltage to run processor<br/> which is moving to secondary mosfets which sure of the voltage of the current it is the Vcore then move the current from the source of last lower mosfets to the capacitors for softening the current then to coil which has the Vcore which feed the processor to run it and finally after make sure whole circuit work correctly the main controller IC generate a beat it means everything work correctly on PGOOD pin (37)
            <center><h2 className='mt-4'><strong><u>#SASKE_EG</u> 😎 ... <u>Your_Gate_For_Tech</u>👌</strong></h2></center>
        </p>
        ,
    },
    {
        id:"3",
        title: "Dos Attack",
        subtitle: "what is Dos Attack and How can We Protect Us from It......",
        day: "sunday",
        date: "23-2-2025",
        time:"11:48 am",
        img: Dos,
        des:
        <p>
            Dos Attack
            Stands for Denial of Service Attack is a network attack it is prevent users to reach network server <br/>
            cause this attack depend on make a lot of requests from server it will consumes server resources and drop it. <br/>
            we can say Server is a huge computer has huge memory and some Special Specifications to work and provide a lot of services that make users browse sites or data it is providing it for 24 hours daily and if something happened and it consumes whole server resources it will crash and can’t provide its services cause server programs will be freeze and users can’t reach to server or use its services.<br/>
            There are also Distributed Denial of Service (DDoS) Attacks in which a multitude of servers are used to exhaust whole resources of victim server.<br/>
            Types of DoS attack:<br/>
            DoS attacks are more of a category than a particular kind of attack. Here is a non-exhaustive list of DoS attack types:
            <ul>
                <li>
                    bandwidth attack
                </li>
                <li>
                    service request flood
                </li>
                <li>
                    SYN flooding attack
                </li>
                <li>
                    ICMP flood attack
                </li>
                <li>
                    peer-to-peer attack
                </li>
                <li>
                    permanent DoS attack
                </li>
                <li>
                    application level flood attack
                </li>
            </ul>
            <center><h2 className='mt-4'><strong><u>#SASKE_EG</u> 😎 ... <u>Your_Gate_For_Tech</u>👌</strong></h2></center>
        </p>
        , 
    },    
    {
        id:"2",
        title: "How Flutter Apps Configration and Running ",
        subtitle: "Are You ready for Journy into Flutter Apps......",
        day:"Sunday",
        date: "23-2-2025",
        time:"11:15 am",
        img: jotvsjit,
        des: 
        <p>
            are two different compilers used in Flutter. AOT is used to translate the Dart code into native machine code in advance during the compilation process, resulting in faster execution times and smaller app sizes. Once compiled, the AOT code can be run directly on the target device without the need for a runtime system. On the other hand, JIT is used during development to compile the Dart code into machine code on-the-fly, allowing developers to more quickly see the output of their changes without having to go a full compilation process. This makes development faster, but the JIT code is usually larger in size and slower to execute than AOT-compiled code. In conclusion, both AOT and JIT are important compilers in Flutter, with AOT being ideal for production environments where performance and app size are important factors, while JIT is great for speeding up development time by providing quick feedback on code changes. It is up to developers to choose. So, do you mean compiling the application into binary code? This is what we will know in the next post?
            <center><h2 className='mt-4'><strong><u>#SASKE_EG</u> 😎 ... <u>Your_Gate_For_Tech</u>👌</strong></h2></center>
        </p>
        , 
    },
    
]


/*
{
    id:"1",
    title: "",
    subtitle: "",
    day:"",
    date: "",
    time:"",
    img: ,
    des:
    
    , 
},
*/